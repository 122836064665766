import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { changeLanguage } from 'i18next'
import { withTranslation } from 'react-i18next'

const SelectMenu = styled.select`
  display: flex;
  align-items: center;
  background: transparent;
  border-radius: 5px;
  color: #fff;
  padding: 2px 10px 2px 0;
`

const ChangeLanguage = ({ i18n, onChange }) => {
  return (
    <div>
      <SelectMenu
        name="lang"
        id="lang"
        defaultValue={i18n.language}
        onChange={(e) => {
          changeLanguage(e.target.value)
        }}
      >
        <option value="zh-CN">简体中文</option>
        <option value="en">English</option>
        <option value="jp">日本语</option>
        <option value="kr">한국어</option>
      </SelectMenu>
    </div>
  )
}

export default withTranslation()(ChangeLanguage)
