import { ApolloClient } from 'apollo-client'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { HttpLink } from 'apollo-link-http'

const { REACT_APP_APOLLOCLIENT_URI, REACT_APP_BLOCKCLIENT_URI } = process.env

export const client = new ApolloClient({
  link: new HttpLink({
    uri: REACT_APP_APOLLOCLIENT_URI,
  }),
  cache: new InMemoryCache(),
  shouldBatch: true,
})

// export const healthClient = new ApolloClient({
//   link: new HttpLink({
//     uri: 'https://api.thegraph.com/index-node/graphql',
//   }),
//   cache: new InMemoryCache(),
//   shouldBatch: true,
// })

// export const v1Client = new ApolloClient({
//   link: new HttpLink({
//     uri: 'https://api.thegraph.com/subgraphs/name/ianlapham/uniswap',
//   }),
//   cache: new InMemoryCache(),
//   shouldBatch: true,
// })

export const stakingClient = new ApolloClient({
  link: new HttpLink({
    uri: 'https://api.thegraph.com/subgraphs/name/way2rach/talisman',
  }),
  cache: new InMemoryCache(),
  shouldBatch: true,
})

export const blockClient = new ApolloClient({
  link: new HttpLink({
    uri: REACT_APP_BLOCKCLIENT_URI,
  }),
  cache: new InMemoryCache(),
})
